

























































































import { Observer } from 'mobx-vue'
import { Component, Provide, Vue } from 'vue-property-decorator'
import { createPostController } from '@/modules/common/dialogs/create-post/create-post-controller'
import { ReferralViewModel } from '../viemmodel/referral-viewmodel'

@Observer
@Component({
  components: {
    'owner-section': () => import('@/modules/common/components/section/owner-section.vue'),
    'my-dao-section': () => import('@/modules/common/components/section/my-dao-section.vue'),

    'referral-thinker': () => import('@/modules/referral/components/referral-thinker.vue'),
    'referral-total-user': () => import('@/modules/referral/components/referral-total-user.vue'),
    'referral-current-tier': () => import('@/modules/referral/components/referral-current-tier.vue'),
    'referral-milestone': () => import('@/modules/referral/components/referral-milestones.vue'),

    'share-link-dialog': () => import('@/modules/referral/dialogs/share-link-dialog.vue'),
    'all-referral-dialog': () => import('@/modules/referral/dialogs/all-referral-dialog.vue'),
    'all-referral-dao-dialog': () => import('@/modules/referral/dialogs/all-referral-dao-dialog.vue'),
    'referral-milestones-dialog': () => import('@/modules/referral/dialogs/referral-milestones-dialog.vue'),
    'tier-referral-detail-dialog': () => import('@/modules/referral/dialogs/tier-referral-detail-dialog.vue'),
    'referal-tabs': () => import('@/modules/referral/components/referal-tabs.vue'),
    'referral-stats': () => import('@/modules/referral/components/referral-stats.vue'),
  },
})
export default class extends Vue {
  createPostController = createPostController

  @Provide() vm = new ReferralViewModel()
}
