import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { referralMilestones } from '@/constants/referral-milestone'
import { ProfileModel } from '@/models/profile-model'
import { connectSocialController } from '@/modules/profile/viewmodels/connect-socical-controller'
import { apiService } from '@/services/api-services'
import { walletStore } from '@/stores/wallet-store'
import { ApexOptions } from 'apexcharts'
import { action, computed, IReactionDisposer, observable, reaction } from 'mobx'
import { asyncAction } from 'mobx-utils'
import moment from 'moment'

const convertUserProfileToReferral = (profile: ProfileModel) => {
  const name = profile?.username
  const referralStatus = profile?.referral_status
  const questStatus = referralStatus === 'completed' ? 'Completed quest' : 'Registered Account'
  const joinDate = profile.createdAt
  const reward = referralStatus === 'completed' ? true : false
  return {
    profile: profile,
    account: name,
    questStatus: questStatus,
    joinDate,
    reward,
  }
}

const convertUserDaoToReferral = (userDao: any) => {
  const name = userDao.profile?.username
  const referralStatus = userDao?.referral_status
  const questStatus = referralStatus === 'completed' ? 'Completed quest' : 'Joined Dao'
  const joinDate = userDao.createdAt
  const reward = referralStatus === 'completed' ? true : false
  return {
    profile: userDao.profile,
    account: name,
    questStatus: questStatus,
    joinDate,
    reward,
  }
}

const convertReferralEarningAction = (referralAction) => {
  const referralProfile: ProfileModel = referralAction?.profileReferral
  const name = referralProfile?.username
  const date = referralAction?.createdAt
  const reward = referralAction?.reward
  const dao = referralAction?.dao
  return {
    profile: referralProfile,
    name,
    date,
    reward,
    dao
  }
}

export interface SummaryInfo {
  referralCount: number
  pendingCount: number
  completedCount: number
  tier: { tier: number; baseReward: number }
}
export class ReferralViewModel {
  private _disposers: IReactionDisposer[]

  constructor() {
    this.init()

    this._disposers = [
      reaction(
        () => this.referralFilter,
        async (filter) => {
          this.fetchReferredUser(filter)
        }
      ),
      reaction(
        () => this.referralDaoFilter,
        async (filter) => {
          this.fetchReferredDao(filter)
        }
      ),
    ]
  }
  private referralMilestones = referralMilestones
  /**
   * TOTAL REFERRAL USER
   */
  @observable referralFilter = { page: 1, pageSize: 10 }
  @observable referralDaoFilter = { page: 1, pageSize: 10 }

  @observable paginationMeta = {}
  @observable recentReferredUsers = []
  @observable referredUsersLoading = false
  @observable referredUsers = []
  @observable summaryInformation?: SummaryInfo = undefined
  @observable referralRewardActions: any[] = []
  @observable referralChartData = []

  @observable daoPaginationMeta = {}
  @observable referredDaos = []
  @observable recentReferredDaos = []
  @observable referredDaosLoading = false
  @observable referralDaoChartData = []


  destroyed() {
    this._disposers.forEach((d) => d())
  }

  @asyncAction *init() {
    this.fetchRecentReferralUser({ page: 1, pageSize: 10 })
    this.fetchSummaryInfo()
    this.fetchReferralRewardActions()
    this.fetchReferralGraphData()

    this.fetchRecentReferralDao({ page: 1, pageSize: 10 })
    this.fetchReferralDaoGraphData()

  }

  @asyncAction *fetchSummaryInfo() {
    try {
      const id = walletStore.userProfile?._id
      if (id) {
        const res = yield apiService.profiles.getRecentReferralSummaryInfo(id)
        this.summaryInformation = res
      }
    } catch (error) {
      snackController.commonError(error)
    }
  }

  @asyncAction *fetchReferralRewardActions() {
    try {
      const id = walletStore.userProfile?._id
      if (id) {
        const res = yield apiService.profiles.getReferralRewardActions(id)
        this.referralRewardActions = res
      }
    } catch (error) {
      snackController.commonError(error)
    }
  }

  @asyncAction *fetchRecentReferralUser(filter) {
    try {
      const id = walletStore.userProfile?._id
      if (id) {
        const res = yield apiService.profiles.getRecentReferralUsers(id, filter)
        if (res && res.data) {
          this.recentReferredUsers = res.data
        }
      }
    } catch (error) {
      snackController.commonError(error)
    }
  }

  @asyncAction *fetchRecentReferralDao(filter) {
    try {
      const id = walletStore.userProfile?._id
      if (id) {
        const res = yield apiService.profiles.getRecentReferralDaos(id, filter)
        if (res && res.data) {
          this.recentReferredDaos = res.data
        }
      }
    } catch (error) {
      snackController.commonError(error)
    }
  }

  @asyncAction *fetchReferredUser(filter) {
    try {
      this.referredUsersLoading = true
      const id = walletStore.userProfile?._id
      if (id) {
        const res = yield apiService.profiles.getRecentReferralUsers(id, filter)
        if (res && res.data) {
          this.referredUsers = res.data
          this.paginationMeta = res.meta
        }
      }
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.referredUsersLoading = false
    }
  }

  @asyncAction *fetchReferredDao(filter) {
    try {
      this.referredDaosLoading = true
      const id = walletStore.userProfile?._id
      if (id) {
        const res = yield apiService.profiles.getRecentReferralDaos(id, filter)
        if (res && res.data) {
          this.referredDaos = res.data
          this.daoPaginationMeta = res.meta
        }
      }
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.referredDaosLoading = false
    }
  }

  @asyncAction *fetchReferralGraphData() {
    try {
      const id = walletStore.userProfile?._id
      if (id) {
        const res = yield apiService.profiles.getReferralGraphData(id, '7d')
        this.referralChartData = res
      }
    } catch (error) {
      snackController.commonError(error)
    }
  }

  @asyncAction *fetchReferralDaoGraphData() {
    try {
      const id = walletStore.userProfile?._id
      if (id) {
        const res = yield apiService.profiles.getReferralDaoGraphData(id, '7d')
        this.referralDaoChartData = res
      }
    } catch (error) {
      snackController.commonError(error)
    }
  }

  @action.bound resetReferredFilter() {
    this.referralFilter = { page: 1, pageSize: 10 }
  }

  @action.bound resetReferredDaoFilter() {
    this.referralDaoFilter = { page: 1, pageSize: 10 }
  }

  @action.bound changeReferredFilter(filter) {
    this.referralFilter = filter
  }

  @action.bound changeReferredDaoFilter(filter) {
    this.referralDaoFilter = filter
  }

  @computed get recentReferredUserDisplay() {
    return this.recentReferredUsers.map((user: ProfileModel) => {
      return convertUserProfileToReferral(user)
    })
  }

  @computed get referredUserDisplay() {
    return this.referredUsers.map((user: ProfileModel) => {
      return convertUserProfileToReferral(user)
    })
  }

  @computed get recentReferredDaoDisplay() {
    return this.recentReferredDaos.map((userDao: any) => {
      return convertUserDaoToReferral(userDao)
    })
  }

  @computed get referredDaoDisplay() {
    return this.referredDaos.map((userDao: any) => {
      return convertUserDaoToReferral(userDao)
    })
  }

  /**
   * CURRENT TIER
   */
  @computed get currentTier(): string {
    if (this.inviteCompleted === 0) return 'You have not referral anyone yet'
    if (this.inviteCompleted >= this.referralMilestones[this.referralMilestones.length - 1].minInvite)
      return 'You have completed the tiers'

    for (let i = 0; i < this.referralMilestones.length - 1; i++) {
      const milestone = this.referralMilestones[i]
      const milestoneNext = this.referralMilestones[i + 1]

      const minInvite = milestone.minInvite
      const maxInvite = milestoneNext.minInvite - 1

      if (minInvite <= this.inviteCompleted && this.inviteCompleted <= maxInvite) {
        return 'TIER ' + (i + 1)
      }
    }

    return ''
  }

  @computed get nextTierRequired() {
    const currentTier = this.summaryInformation?.tier?.tier || 0
    if (currentTier === 5) return ''
    const nextTier = currentTier + 1
    const nextTierRequire =
      (referralMilestones.find((item) => item.tier === nextTier)?.minInvite as number) - this.inviteCompleted
    return `${nextTierRequire} invites more to reach Tier ${nextTier}`
  }

  @computed get referralMilestonesData() {
    return this.referralMilestones.map((milestone, index) => {
      const milestoneNext = this.referralMilestones[index + 1]
      if (!milestoneNext) {
        return {
          ...milestone,
          completed: this.inviteCompleted >= milestone.minInvite,
        }
      }

      const minInvite = milestone.minInvite
      const maxInvite = milestoneNext.minInvite - 1

      const numberInvitationInMileStone = maxInvite - minInvite + 1
      const numberInvitedInMileStone = this.inviteCompleted - minInvite + 1

      if (this.inviteCompleted < minInvite) {
        return { ...milestone, process: 0, completed: false }
      } else if (minInvite <= this.inviteCompleted && this.inviteCompleted <= maxInvite) {
        const process = (numberInvitedInMileStone * 100) / numberInvitationInMileStone
        return { ...milestone, process: process, completed: true }
      } else {
        return { ...milestone, process: 100, completed: true }
      }
    })
  }

  /**
   * SHARE YOUR REFERRAL LINK DIALOG
   */
  @observable shareLinkDialog = false

  @action.bound openShareLinkDialog(val: boolean) {
    this.shareLinkDialog = val
  }

  /**
   * ALL REFERRAL DIALOG
   */
  requestStatus = [
    { key: 'all', title: 'All quest status' },
    { key: 'completed', title: 'Completed quest' },
    { key: 'pending', title: 'Incomplete' },
  ]
  joinedStatus = ['Lated joined', 'Latest joined']

  @observable allReferallDialog = false
  @observable allReferallDaoDialog = false

  @observable requestStatusSelected = this.requestStatus[0]
  @observable joinedStatusSelected = this.joinedStatus[0]

  @observable selectedType: 'chart' | 'list' = 'list'

  @observable pageNumber = 1

  @action.bound openAllReferallDialog(val: boolean) {
    this.allReferallDialog = val
    if (val) {
      this.fetchReferredUser(this.referralFilter)
    } else {
      this.referredUsers = []
      this.resetReferredFilter()
    }
  }

  @action.bound openAllReferallDaoDialog(val: boolean) {
    this.allReferallDaoDialog = val
    if (val) {
      this.fetchReferredDao(this.referralDaoFilter)
    } else {
      this.referredUsers = []
      this.resetReferredDaoFilter()
    }
  }

  @action.bound requestStatusSelectedChange(val: string) {
    const selectedOption = this.requestStatus.find((item) => item.title === val)
    if (selectedOption) {
      this.requestStatusSelected = selectedOption
      if (selectedOption.key === 'completed') this.changeReferredFilter({ ...this.referralFilter, status: 'completed' })
      else if (selectedOption.key === 'pending')
        this.changeReferredFilter({ ...this.referralFilter, status: 'pending' })
      else this.changeReferredFilter({ ...this.referralFilter, status: 'all' })
    }
  }

  @action.bound daoRequestStatusSelectedChange(val: string) {
    const selectedOption = this.requestStatus.find((item) => item.title === val)
    if (selectedOption) {
      this.requestStatusSelected = selectedOption
      if (selectedOption.key === 'completed') this.changeReferredDaoFilter({ ...this.referralDaoFilter, status: 'completed' })
      else if (selectedOption.key === 'pending')
        this.changeReferredDaoFilter({ ...this.referralDaoFilter, status: 'pending' })
      else this.changeReferredDaoFilter({ ...this.referralDaoFilter, status: 'all' })
    }
  }

  @action.bound joinedStatusSelectedChange(val: string) {
    this.joinedStatusSelected = val
  }

  @action.bound selectedTypeChange(val: 'chart' | 'list') {
    this.selectedType = val
  }

  /**
   * REFERRAL TIER DIALOG
   */
  @observable referralTierDialog = false

  @action.bound openReferralTierDialog(val: boolean) {
    this.referralTierDialog = val
  }

  /**
   *  TIER REFERRAL DETAIL DIALOG
   */
  @observable tierReferralDetailDialog = false

  @action.bound openTierReferralDetailDialog(val: boolean) {
    this.tierReferralDetailDialog = val
  }

  @computed get yourReferralLink() {
    const host = process.env.VUE_APP_HOST
    const profileId = walletStore.userProfile?._id
    return `${host}?ref=${profileId}`
  }

  @computed get series() {
    const chartData = this.referralChartData
    const currentTime = moment()
    const startTime = currentTime.subtract(7, 'day')
    const completedSeries: number[] = []
    const pendingSeries: number[] = []
    for (let i = 0; i < 7; i++) {
      const time: any = startTime.add(1, 'day').format('YYYY-MM-DD')
      const completedData: any = chartData.find(
        (item) =>
          moment((item as any)?._id?.date).format('YYYY-MM-DD') === time && (item as any)?._id.status === 'completed'
      )
      const pendingData: any = chartData.find(
        (item) =>
          moment((item as any)._id?.date).format('YYYY-MM-DD') === time && (item as any)?._id.status === 'pending'
      )

      if (completedData && completedData?.count) {
        completedSeries.push(completedData.count)
      } else {
        completedSeries.push(0)
      }
      if (pendingData && pendingData?.count) {
        pendingSeries.push(pendingData.count)
      } else {
        pendingSeries.push(0)
      }
    }
    return [
      {
        name: 'Complete quest',
        data: completedSeries,
      },
      {
        name: 'Incomplete quest',
        data: pendingSeries,
      },
    ]
  }

  @computed get daoSeries() {
    const chartData = this.referralDaoChartData
    const currentTime = moment()
    const startTime = currentTime.subtract(7, 'day')
    const completedSeries: number[] = []
    const pendingSeries: number[] = []
    for (let i = 0; i < 7; i++) {
      const time: any = startTime.add(1, 'day').format('YYYY-MM-DD')
      const completedData: any = chartData.find(
        (item) =>
          moment((item as any)?._id?.date).format('YYYY-MM-DD') === time && (item as any)?._id.status === 'completed'
      )
      const pendingData: any = chartData.find(
        (item) =>
          moment((item as any)._id?.date).format('YYYY-MM-DD') === time && (item as any)?._id.status === 'pending'
      )

      if (completedData && completedData?.count) {
        completedSeries.push(completedData.count)
      } else {
        completedSeries.push(0)
      }
      if (pendingData && pendingData?.count) {
        pendingSeries.push(pendingData.count)
      } else {
        pendingSeries.push(0)
      }
    }
    console.log("==========", [
      {
        name: 'Complete quest',
        data: completedSeries,
      },
      {
        name: 'Incomplete quest',
        data: pendingSeries,
      },
    ])
    return [
      {
        name: 'Complete quest',
        data: completedSeries,
      },
      {
        name: 'Incomplete quest',
        data: pendingSeries,
      },
    ]
  }

  @computed get categories() {
    const currentTime = moment()
    const categories: string[] = []
    const startTime = currentTime.subtract(7, 'day')
    for (let i = 0; i < 7; i++) {
      const time: any = startTime.add(1, 'day').format('DD-MM-YYYY')
      categories.push(time)
    }

    return categories
  }

  @computed get options(): ApexOptions {
    return {
      chart: {
        background: '#ffffff00',
        type: 'line',
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
        width: 2,
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left',
        markers: {
          radius: 3,
        },
      },
      colors: ['#D6F76B', '#FF426F', '#5947F5'],
      grid: {
        borderColor: '#484849',
      },
      xaxis: {
        categories: this.categories,
      },
      theme: {
        mode: 'dark',
      },
    }
  }

  @computed get requestStatusFilterOptions() {
    return this.requestStatus.map((status) => status.title)
  }

  @computed get requestStatusFilterSelectedOption() {
    return this.requestStatusSelected.title
  }

  @computed get facebookLink() {
    return `https://www.facebook.com/sharer/sharer.php?u=${this.yourReferralLink}&amp;src=sdkpreparse`
  }

  @computed get twitterLink() {
    return `https://twitter.com/intent/tweet?url=${this.yourReferralLink}`
  }

  @computed get redditLink() {
    return `https://www.reddit.com/submit?url=${this.yourReferralLink}`
  }

  @computed get kakaoLink() {
    return `https://story.kakao.com/share?url=${this.yourReferralLink}`
  }

  @computed get totalReferralUser() {
    return this.summaryInformation?.referralCount || 0
  }

  @computed get incompleteInvite() {
    return this.summaryInformation?.pendingCount || 0
  }

  @computed get inviteCompleted() {
    return this.summaryInformation?.completedCount || 0
  }

  @computed get rewardBase() {
    return this.summaryInformation?.tier?.baseReward
  }

  @computed get tier1Reward() {
    return this.referralRewardActions.filter((item) => item?.reward === 10)
  }

  @computed get tier1RewardTotal() {
    let total = 0
    this.tier1Reward.forEach((item) => {
      total = total + item.reward * item.multipler
    })
    return total
  }

  @computed get tier1InviteRewardTotal() {
    let total = 0
    this.tier1Reward.filter(item => !item.dao).forEach((item) => {
      total = total + item.reward * item.multipler
    })
    return total
  }

  @computed get tier1DaoRewardTotal() {
    let total = 0
    this.tier1Reward.filter(item => item.dao).forEach((item) => {
      total = total + item.reward * item.multipler
    })
    return total
  }

  @computed get tier2Reward() {
    return this.referralRewardActions.filter((item) => item?.reward === 15)
  }

  @computed get tier2RewardTotal() {
    let total = 0
    this.tier2Reward.forEach((item) => {
      total = total + item.reward * item.multipler
    })
    return total
  }

  @computed get tier2InviteRewardTotal() {
    let total = 0
    this.tier2Reward.filter(item => !item.dao).forEach((item) => {
      total = total + item.reward * item.multipler
    })
    return total
  }

  @computed get tier2DaoRewardTotal() {
    let total = 0
    this.tier2Reward.filter(item => item.dao).forEach((item) => {
      total = total + item.reward * item.multipler
    })
    return total
  }

  @computed get tier3Reward() {
    return this.referralRewardActions.filter((item) => item?.reward === 20)
  }

  @computed get tier3InviteRewardTotal() {
    let total = 0
    this.tier3Reward.filter(item => !item.dao).forEach((item) => {
      total = total + item.reward * item.multipler
    })
    return total
  }

  @computed get tier3DaoRewardTotal() {
    let total = 0
    this.tier3Reward.filter(item => item.dao).forEach((item) => {
      total = total + item.reward * item.multipler
    })
    return total
  }

  @computed get tier3RewardTotal() {
    let total = 0
    this.tier3Reward.forEach((item) => {
      total = total + item.reward * item.multipler
    })
    return total
  }
  @computed get tier4Reward() {
    return this.referralRewardActions.filter((item) => item?.reward === 25)
  }

  @computed get tier4RewardTotal() {
    let total = 0
    this.tier4Reward.forEach((item) => {
      total = total + item.reward * item.multipler
    })
    return total
  }

  @computed get tier4InviteRewardTotal() {
    let total = 0
    this.tier4Reward.filter(item => !item.dao).forEach((item) => {
      total = total + item.reward * item.multipler
    })
    return total
  }

  @computed get tier4DaoRewardTotal() {
    let total = 0
    this.tier4Reward.filter(item => item.dao).forEach((item) => {
      total = total + item.reward * item.multipler
    })
    return total
  }

  @computed get tier5Reward() {
    return this.referralRewardActions.filter((item) => item?.reward === 30)
  }

  @computed get tier5RewardTotal() {
    let total = 0
    this.tier5Reward.forEach((item) => {
      total = total + item.reward * item.multipler
    })
    return total
  }

  @computed get tier5InviteRewardTotal() {
    let total = 0
    this.tier5Reward.filter(item => !item.dao).forEach((item) => {
      total = total + item.reward * item.multipler
    })
    return total
  }

  @computed get tier5DaoRewardTotal() {
    let total = 0
    this.tier5Reward.filter(item => item.dao).forEach((item) => {
      total = total + item.reward * item.multipler
    })
    return total
  }

  @computed get totalReferralReward() {
    let total = 0
    this.referralRewardActions.forEach((item) => {
      total = total + item.reward * item.multipler
    })
    return total
  }

  @computed get totalInviteReferralReward() {
    let total = 0
    this.referralRewardActions.filter(item => !item?.dao).forEach((item) => {
      total = total + item.reward * item.multipler
    })
    return total
  }

  @computed get totalDaoReferralReward() {
    let total = 0
    this.referralRewardActions.filter(item => item?.dao).forEach((item) => {
      total = total + item.reward * item.multipler
    })
    return total
  }

  @computed get recentReferralEarningActions() {
    return this.referralRewardActions.map((item) => {
      return convertReferralEarningAction(item)
    })
  }

  @computed get recentInviteReferralEarningActions() {
    return this.referralRewardActions.filter(item => !item?.dao).map((item) => {
      return convertReferralEarningAction(item)
    })
  }

  @computed get recentDaoReferralEarningActions() {
    return this.referralRewardActions.filter(item => item?.dao).map((item) => {
      return convertReferralEarningAction(item)
    })
  }

  @computed get tier1ReferralEarningAction() {
    return this.tier1Reward.map((item) => {
      return convertReferralEarningAction(item)
    })
  }

  @computed get tier2ReferralEarningAction() {
    return this.tier2Reward.map((item) => {
      return convertReferralEarningAction(item)
    })
  }

  @computed get tier3ReferralEarningAction() {
    return this.tier3Reward.map((item) => {
      return convertReferralEarningAction(item)
    })
  }

  @computed get tier4ReferralEarningAction() {
    return this.tier4Reward.map((item) => {
      return convertReferralEarningAction(item)
    })
  }

  @computed get tier5ReferralEarningAction() {
    return this.tier5Reward.map((item) => {
      return convertReferralEarningAction(item)
    })
  }

  @computed get tierRewardData() {
    return [
      this.tier1ReferralEarningAction,
      this.tier2ReferralEarningAction,
      this.tier3ReferralEarningAction,
      this.tier4ReferralEarningAction,
      this.tier5ReferralEarningAction,
    ]
  }
}
